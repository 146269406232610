.outlined-drop-down-select .MuiFormLabel-root[data-shrink="false"] {
  color: #a3a3a3 !important;
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.outlined-drop-down-select .MuiFormLabel-root[data-shrink="true"] {
  color: #eb770c !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  top: 6px;
}

.outlined-drop-down-select .MuiOutlinedInput-root {
  border-radius: 9px !important;
  color: #eb770c;
  position: relative;
}

.outlined-drop-down-select.filled .MuiOutlinedInput-notchedOutline {
  background-color: #ffffff !important;
}

.outlined-drop-down-select .MuiOutlinedInput-notchedOutline {
  bottom: 5px !important;
  right: 2px !important;
  top: 0 !important;
  left: 2px !important;
  border: 1px solid #eeeded !important;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.04) !important;
  background-color: #f5f5f5 !important;
}

.outlined-drop-down-select .MuiInputBase-input {
  color: #eb770c !important;
  z-index: 2;
  position: relative;
  background-color: transparent !important;
}
.outlined-drop-down-select .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #eb770c !important;
}

.outlined-drop-down-select .MuiSelect-select {
  text-align: left !important;
}

.outlined-drop-down-select .MuiSelect-icon {
  z-index: 2;
}
.outlined-drop-down-select .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #eb770c !important;
}

.outlined-drop-down-select
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  background-color: #ffffff !important;
}

.outlined-drop-down-select .MuiOutlinedInput-root.Mui-focused {
  background-color: #ffffff !important;
}

.MuiInputBase-input
  .MuiOutlinedInput-input
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #eb770c;
}

.drop-down-options {
  white-space: normal !important;
  word-wrap: break-word !important;
}
