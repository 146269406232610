.need-assess-heading {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 0 0 4px;
}

.need-assess-content {
  color: #858585;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
