.cards {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 0;
  }
  
  .card-1 {
    height: 100%;
    border-radius: 16.923px;
    border: 0.5px solid #E4E4E4;
    background: #fff;
    box-shadow: 0px 0px 61px 0px rgba(0, 0, 0, -0.01);
    overflow-x: hidden;
  }
  
  .card-2 {
    height: 100%;
    width: 92%;
    position: absolute;
    top: 3%;
    left: 4%;
    border-radius: 16.923px;
    background: #e7e7e7;
    box-shadow: 0px 4.23077px 64.51923px 0px rgba(0, 0, 0, 0.02);
    z-index: -1;
  }
  
  .card-3 {
    height: 100%;
    width: 85%;
    position: absolute;
    top: 6%;
    left: 8%;
    z-index: -2;
    border-radius: 16.923px;
    background: #f2f2f2;
  }
  
  .card-4 {
    height: 100%;
    width: 74%;
    position: absolute;
    top: 8.5%;
    left: 14%;
    z-index: -3;
    border-radius: 16.923px;
    background: #f8f8f8;
    box-shadow: 0px 0px 84.61539px 0px rgba(0, 0, 0, 0.15);
  }
  