@keyframes pulsing {
  0% {
    /* Optional: define initial state if needed */
  }
  50% {
    box-shadow: 0px 0px 19.9px 0px #ffca9af2;
    border: 9px solid #ffbf84;
  }
  100% {
    border: 7px solid #fff0e3;
  }
}

.pulsing-button {
  animation: pulsing 1s infinite;
  animation-delay: 200ms;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
}

@keyframes flashing-recording-dot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
