.resultPage {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 42px 32px 10px;
}

.back-arrow{
  position: absolute;
  left: 20px;
  top: 15px;
}

.header-text {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  padding-right: 20px;
}

.header-grade-text {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.divider {
  border-top: 1px solid #dedede;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25vh;
}

.infoCardsContainer {
  padding: 20px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quicksight-card {
  padding: 20px 30px 0px;
}

.quicksight-card-header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.quicksight-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.paragraph-wrapper-card {
  padding: 24px 24px 0px;
}

.paragraph-card {
  border-radius: 9px;
  border: 1px solid rgba(55, 55, 55, 0.07);
  background: #fff;
  box-shadow: 0px 15px 35.7px -24px rgba(0, 0, 0, 0.13);
  padding-bottom: 32px;
}

.para-heading {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 8px;
}

.audio-player-wrapper {
  padding: 0px 8px;
}

.audio-player {
  border-radius: 10px;
  border: 0.5px solid #e0e0e0;
  background: rgba(37, 115, 217, 0.25);
  padding: 0px 8px 0px 0px;
}

.paragraph-para {
  padding: 32px 16px 0px;
  overflow: hidden;
  color: #373737;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.266px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.detailed-result-card {
  padding: 24px;
}

.detailed-result-card-heading {
  padding: 8px;
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detailed-result-grid {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px;
  gap: 24px;
}

.resultPage-btn-wrapper {
  padding: 0px 24px 16px;
}

.resultPage-btn {
  border-radius: 11px;
  background-color: #bb248a;
  display: inline-flex;
  padding: 15px 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cwpm-info {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #797b7c;
  padding-bottom: 4px;
}

.nextButton {
  width: 100%;
  text-transform: none;
  border-radius: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 100px;
  background: #e47714;
}

.tryanother {
  width: 100%;
  text-transform: none;
  border-radius: 12px;
  color: #e47714;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 100px;
}

.textarea {
  width: 271px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(55, 55, 55, 0.03);
  resize: none;
}

.body1 {
  color: #797b7c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.submit {
  display: inline-block;
  padding: 11px 86px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #e47714;
  margin-left: 6px;
  margin-bottom: 10px;
}

.feedback-dialog .MuiDialog-paper {
  border-radius: 22px !important;
}

img.en-spreaded-letters {
  position: absolute;
  left: 0;
  top: 0;
}

img.hi-spreaded-letters {
  position: absolute;
  right: 0;
  bottom: 0;
}

.sticky-wrapper {
  position: sticky;
  padding: 2% 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  z-index: 10;
  border-top: 0.5px solid #0000001a;
  box-shadow: 0px -23px 50px 0px #00000009;
  border-radius: 16px 16px 0px 0px;
}

.info-icon-container {
  display: flex;
  flex-direction: row;
}

.info-icon {
  font-size: 18px !important;
  margin-right: 6px;
  padding-bottom: 38px;
}
