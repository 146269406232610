.additional-feedback-dialog-title .MuiDialogTitle-root {
  padding: 14px 16px 0px 16px !important;
}

.additionalFeedback .feedback-input-text-field {
  width: 100%;
  padding-bottom: 0;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
}

.additionalFeedback .feedback-form .MuiTypography-root{
    font-size: 14px! important;
    color: #373737! important;
}

.additionalFeedback .feedback-form .MuiSvgIcon-root{
    color: #373737! important;
}
