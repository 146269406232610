.grant-permissions-steps-dialog .MuiDialog-paper {
  border-radius: 12px !important;
  margin: 0 16px;
  width: 100%;
}

.grant-permissions-steps-dialog .MuiDialogTitle-root {
  padding: 0 0 !important;
}

.grant-permissions-steps-dialog .MuiDialogContent-root {
  padding: 0 0 !important;
}

.grant-permissions-steps-dialog .grant-permissions-steps-dialog-heading {
  color: #373737;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 32px 20px 0;
}

.grant-permissions-steps-dialog .grant-permissions-steps-dialog-content {
  color: #373737;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 172%;
  margin: 0 16px 10px;
}

.grant-permissions-steps-dialog .grant-permissions-steps {
  color: #878787;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 172%;
  margin: 0 28px 32px;
  text-align: left;
}

.grant-permissions-steps-dialog .grant-permissions-steps-text {
  color: #636363;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 172%;
  background: #EFEFEF;
  display: inline-block;
  border-radius: 2px;
  padding: 0 4px 0;
  margin: 2px 0 0 5px;
}
