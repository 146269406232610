.saveResult .MuiDialog-paper{
    border-radius: 12px! important;
    width: 100%;
    margin: 0 20px;
}

.saveResult .MuiDialogTitle-root{
    padding: 0px 0px! important;
    text-align: center;
}

.MuiDialogContent-root{
    padding: 2px 14px 20px !important
}

.MuiButton-startIcon .retake-icon{
    font-size: 24px! important;
    transform: rotateY(180deg) rotate(132deg)! important
}