.vopa-input-field-screen-heading-text {
  color: #373737;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.vopa-input-field-screen-container {
  text-align: center;
  /* margin: 20% 0%; */
}

.demo-input-field-heading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10% 32px 56px;
  text-align: left;
}

.vopa-input-field-screen-subheading-text {
  color: rgba(133, 133, 133, 1);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.demo-input-fields-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0% 10% 10%;
}

.student-selector-div {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input-label {
  color: #373737;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 150%;
  font-weight: 400;
  text-align: left;
  padding: 0px 4px;
}

.asterisk {
  color: #e70000;
}

.sticky-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 12px;
  margin-bottom: 0;
}

@media screen and (max-height: 700px) {
  .sticky-button-container {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
    z-index: 2;
  }
}

@media screen and (max-height: 850px) {
  .sticky-button-container.large {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
    z-index: 2;
  }
}

@media (min-height: 1000px) {
  .vopa-input-field-screen-container {
    margin: 20% 0%;
  }
}
