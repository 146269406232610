.error-dialog .MuiDialog-paper {
    border-radius: 12px! important;
    margin: 16px! important;
    width: 100%! important;
}

.error-dialog .MuiDialogContent-root {
    padding: 0px 14px 15px 14px! important;
}

.error-dialog .MuiButton-startIcon{
    margin-right: 10px! important;
}

.MuiButton-startIcon .retake-icon{
    font-size: 24px! important;
    transform: rotateY(180deg) rotate(132deg)! important
}