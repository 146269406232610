.need-permissions-container {
  /* overflow: scroll; */
  height: 100vh;
  /* background-color: #f4f4f4; */
}

.need-permissions-screen-heading {
  color: #373737;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 4vh 0 0 5%;
}

.need-permissions-screen-desc {
  color: #858585;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 12px 5% 50px;
}

.need-to-assess-text {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.61px;
  letter-spacing: 0.19px;
  text-align: center;
}

.need-assess-container {
  border-radius: 15px;
  border: 0.5px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 2px 52px 0px rgba(0, 0, 0, 0.02);
  padding: 24px 7%;
  margin: 15px 7% 0;
}

.need-permissions-container .MuiButtonBase-root {
  height: 50px;
  width: 64%;
  text-transform: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins";
  margin: 32px 0px 8%;
  background-color: #e47714;
}

@media (hover: none) {
  .need-permissions-container .MuiButtonBase-root:hover {
    background-color: #e47714 !important;
  }
}

@media screen and (max-height: 720px) {
  .sticky-approve-button {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
  }
}
@media only screen and (min-height: 731px) {
  .need-permissions-container .MuiButtonBase-root {
    height: 50px;
    width: 64%;
    text-transform: none;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 16px;
    font-family: "Poppins";
    margin: 5vh 0px 8%;
    background-color: #e47714;
  }
}

.need-permissions-cloud {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 0px 7vw;
  z-index: -1;
}

.blue-cloud {
  position: fixed;
  top: 20vh;
  z-index: -1;
}

.pink-cloud {
  position: fixed;
  top: 14vh;
  right: 15vw;
  z-index: -1;
}

.cloudsBottomOrange {
  display: flex;
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 20vw;
  width: 100%;
}
