.parent-container {
    text-align: center;
}

.child-container {
    position: relative;
    display: inline-block;
}

.student-distance-img {
    width: 84px;
    height: 122.212px;
    margin: 52px 0px 47px;
}

.handle-mobile-gif {
    top: 29%;
    left: 35%;
    z-index: -1;
}