* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "SakalBharati";
  src: url("../public/fonts/SakalBharati/SakalBharati.ttf");
}
