.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0px 8%;
    margin: -20vh 0 0;
}

.loader-image {
    width: 200px; /* Adjust the width as needed */
    height: auto;
}

.loader-heading {
    /* margin-top: 20px; Adjust margin as needed */
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #373737;
    margin: -10vh 0 0;
}

.loader-text {
    /* margin-top: 12px; Adjust margin as needed */
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #373737;
}

.loader-bottom-text-wrapper {
    position: fixed;
    bottom: 0px;
    background-color: #FFF5EC;
    padding: 16px 8%;
}

.loader-bottom-text {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #373737;
    text-align: center;
}

