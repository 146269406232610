.splashScreenDiv {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    #3c4ac7 0%,
    #f17809 0.01%,
    rgba(194, 60, 206, 0.86) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.headingText {
  width: 85%;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 150%;
  font-family: "Poppins";
}

.language-selector {
  display: flex;
  justify-content: flex-start;
  margin-left: 45vw;
  border: 1px solid #ffffff;
  background: #d2d2d23b;
  border-radius: 7px;
  width: 140px;
}

.container {
  position: relative;
  width: 23.75vh;
  height: 23.75vh;
  margin-top: 5vh;
}

.ellipse {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ellipse1 {
  background-color: rgba(217, 217, 217, 0.05);
  width: 100%;
  height: 100%;
}

#ellipse2 {
  background-color: rgba(217, 217, 217, 0.1);
  width: 79%;
  height: 79%;
}

#ellipse3 {
  background-color: rgba(217, 217, 217, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 55%;
}

.letter {
  color: #fff;
  font-size: 44px;
  font-weight: 275;
  line-height: 150%;
  font-family: "Poppins";
  mix-blend-mode: lighten;
}

.getStartedButton {
  display: inline-flex;
  padding: 12px 68px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #fff;
  color: #bb248a;
  font-size: 16px;
  font-family: "Poppins";
  line-height: 150%;
  font-weight: 400;
}

@media screen and (max-height: 720px) {
  .welcome-screen-sticky-button {
    position: sticky;
    bottom: 0;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
  }
}
