.infoCard {
    border-radius: 9px;
    border: 1px solid rgba(55, 55, 55, 0.07);
    box-shadow: 0px 15px 35.7px -24px rgba(0, 0, 0, 0.13);
    display: flex;
    padding: 21px 15px;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins";
}

.card-info {
    color: #373737;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cardPart1 {
    display: flex;
    align-items: center;
    gap: 12px;
}

.card-icon {
    display: flex;
    align-items: center;
}

.card-value {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card-value.cwpm {
    color: #FF8A00;
}

.card-value.wordsaloud {
    color: #00AC12;
}

.card-value.accuracy {
    color: #863be6;
  }

.valuePart1 {
    font-size: 32px;
}

.valueSlash {
    font-size: 14px;
}

.valuePart2 {
    font-size: 12px;
}