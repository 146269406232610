.select-assessment-lang-heading-container {
  position: relative;
  padding: 8vh 6% 0;
  z-index: 100;
}

.select-assessment-lang-heading-container .heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: rgba(55, 55, 55, 1);
}

.select-assessment-lang-heading-container .heading-content {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(133, 133, 133, 1);
}

.select-assessment-lang-next-button {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
  padding-bottom: 7%;
}
.custom-selector-chip {
  padding: 0 5% 24vh;
  position: relative;
  z-index: 100;
}

@media screen and (max-height: 720px) {
  .sticky-next-button {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    padding: 20px 0;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    z-index: 1;
  }
  .select-assessment-lang-next-button {
    padding-bottom: 0%;
  }
}

img.assess-lang-cloud1 {
  position: fixed;
  top: 9vh;
  left: 70%;
}

img.assess-lang-cloud2 {
  position: fixed;
  top: 17vh;
}

img.assess-lang-cloud3 {
  position: fixed;
  top: 24vh;
  left: 60%;
}

img.assess-lang-cloud4 {
  position: fixed;
  top: 51vh;
}

img.assess-lang-cloud5 {
  position: fixed;
  top: 51vh;
  right: 0;
}

img.assess-lang-cloud6 {
  position: fixed;
  bottom: 34vh;
  left: 8%;
}

img.assess-lang-cloud7 {
  position: fixed;
  bottom: 31vh;
  left: 21%;
}

img.assess-lang-cloud8 {
  position: fixed;
  bottom: 24vh;
  right: 18%;
}

img.assess-lang-cloud9 {
  position: fixed;
  bottom: 0;
}
