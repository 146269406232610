.outlined-textfield .MuiFormLabel-root[data-shrink="false"] {
  color: #a3a3a3 !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.outlined-textfield .MuiFormLabel-root[data-shrink="true"] {
  color: #eb770c !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  top: 9px;
}

.outlined-textfield .MuiOutlinedInput-root {
  border-radius: 9px !important;
  color: #eb770c;
  position: relative;
}

.outlined-textfield.filled .MuiOutlinedInput-notchedOutline {
  background-color: #ffffff !important;
}

.outlined-textfield .MuiOutlinedInput-notchedOutline {
  bottom: 6px !important;
  right: 2px !important;
  top: 2px !important;
  left: 2px !important;
  border: 1px solid #eeeded !important;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.04) !important;
  background-color: #f5f5f5 !important;
}
.outlined-textfield .MuiInputBase-input {
  color: #eb770c !important;
  z-index: 2;
  position: relative;
  background-color: transparent !important;
}

.outlined-textfield
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  background-color: #ffffff !important;
}

.outlined-textfield .MuiOutlinedInput-root.Mui-focused {
  background-color: #ffffff !important;
}

.outlined-textfield .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #eb770c !important;
}

.MuiInputBase-input
  .MuiOutlinedInput-input
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #eb770c;
}
