.vopa-instructions-screen-heading {
  color: #373737;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin: 16% 0 0 0;
}

.vopa-instruction-heading {
  color: #373737;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 18px;
}

.vopa-instruction-desc-span {
  font-weight: 700;
}

.vopa-instruction-screen-button-container {
  display: flex;
  padding: 30px 16px 20px 2px;
  justify-content: space-between;
}

.vopa-instruction-desc {
  color: #858585;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: 0.15px;
  margin: 0 34px;
}

.vopa-instructions-screen-desc {
  color: #858585;
  text-align: center;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: 0.15px;
  margin: 2% 18% 8% 18%;
}

.seen-instructions-before-text {
  color: #858585;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  margin: 16% 0 5px;
  text-align: center;
}

.cards-component-vopa-instruction {
  margin: 0 12% 0;
}

.vopa-instruction-button {
  text-align: center;
  margin: 0 0 10%;
}

.sticky {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 14px;
  margin-bottom: 0;
}

.swiper-paginations {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.swiper-pagination-bullet {
  width: 4px !important;
  height: 4px !important;
}

.swiper-pagination-bullet-active {
  background-color: #7a7a7a !important;
  width: 7.031px !important;
  height: 7.031px !important;
}

.vopa-instruction-screen-back-button {
  font-family: Poppins !important;
  padding: 4px 18px !important;
  border: none !important;
  background-color: white;
}

.vopa-instruction-screen-next-button {
  border-radius: 6px !important;
  color: #FFF !important;
  font-family: Poppins !important;
  border: none !important;
  padding: 4px 18px !important;
}

.vopa-instruction-continue-skip-button {
  height: 45px !important;
  width: 65% !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: Poppins !important;
  border-radius: 12px !important;
  border: 1px solid #E47714 !important;
}

@media screen and (max-height: 720px) {
  .sticky {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
    z-index: 1000;
  }

  .seen-instructions-before-text {
    margin: 2% 0 5px;
  }

  .vopa-instruction-button {
    margin: 0 0 1%;
  }

  .cards-component-vopa-instruction {
    margin: 0 12% 30px;
  }
}