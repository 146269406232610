.enter-pwd-heading-text {
  color: #373737 !important;
  font-family: "Poppins" !important;
  font-size: 24px !important;
  text-align: center !important;
}

.enter-pwd-heading-text-msg {
  color: #373737 !important;
  font-family: "Poppins" !important;
  text-align: center !important;
  font-size: small !important;
}

.enter-pwd-container {
  display: flex;
  flex-direction: column;
  padding: 50% 32px 56px;
  gap: 4px;
  align-items: center;
}

.enter-pwd-button {
  text-transform: none !important;
  font-size: 18px !important;
  margin-top: 30px !important;
  width: 70%;
  font-family: Poppins !important;
}

.enter-pwd-text-field {
  width: 70%;
}
