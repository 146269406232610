.result-card {
  border-radius: 9px;
  border: 1px solid;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.result-heading {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.result-value-div {
  width: 85%;
  border-radius: 9px;
  border: 0.5px solid rgba(55, 55, 55, 0.29);
  background: #fff;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.result-value {
  color: #373737;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
