.vopa-welcome-screen-container {
  padding: 28px 20px;
  margin: 20px 28px;
}

.vopa-welcome-screen-heading-text {
  text-align: center;
  color: #373737;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 27px;
}

.vopa-welcome-screen-content-wrapper {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vopa-welcome-screen-content {
  color: #858585;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.language-change-container {
  display: inline-flex;
  color: rgba(37, 115, 217, 0.74);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 13px 40px 30px;
  user-select: none;
}

.language-change-container strong {
  font-weight: 500;
  color: rgba(37, 115, 217, 0.74);
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
}

@media only screen and (min-width: 451px) {
  .vopa-welcome-screen-container {
    margin: 4% 29px 60px 29px;
  }
}

.cloudTop {
  display: flex;
  position: fixed;
  top: 5vh;
  z-index: -1;
  width: 100%;
}

.cloudTop img {
  width: 80%;
}

.cloudsBottom {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
}

.cloudsBottom img {
  width: 100%;
}

.sticky-button {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-bottom: 0;
}

@media screen and (max-height: 750px) {
  .sticky-button {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    border-top: 0.5px solid #0000001a;
    box-shadow: 0px -23px 50px 0px #00000009;
    border-radius: 16px 16px 0px 0px;
  }
}
