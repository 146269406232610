.save-audio-confirmation-drawer .MuiDrawer-paper {
  background: #fff !important;
}

.save-audio-confirmation-drawer .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(3px) !important;
}

.save-audio-confirmation-drawer .listen-to-recording-text {
  color: #373737;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.304px;
  text-align: center;
  margin: 35px 0 16px;
}

.save-audio-confirmation-text {
  color: #373737;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.304px;
  margin: 21px 10% 33px;
}

.PrivateSwipeArea-root {
  height: 0px !important;
}
