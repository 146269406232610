.abbreviation-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(55, 55, 55, 1);
}

.label-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(55, 55, 55, 1);
}

.custom-selector-chip-container {
  display: flex;
  margin: 0px 0px 3.5vh;
  justify-content: space-between;
  border-radius: 11px;
  align-items: center;
  padding: 12px 22px;
  border: 1px solid rgba(55, 55, 55, 0.21);
}

.custom-selector-chip-container.en {
  background: rgb(255, 218, 228);
}

.custom-selector-chip-container.gu {
  background: rgb(255, 218, 228);
}
.custom-selector-chip-container.hi {
  background: rgba(211, 239, 255, 1);
}

.custom-selector-chip-container.en.active {
  border: 1.5px solid rgb(255, 35, 94);
}

.custom-selector-chip-container.hi.active {
  border: 1.5px solid rgba(91, 195, 255, 1);
}

.custom-selector-checkbox-container {
  background: rgb(255, 255, 255);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(55, 55, 55, 0.21);
}

.custom-selector-checkbox-container.en {
  border: 0.5px solid rgba(255, 35, 94, 1);
}

.custom-selector-checkbox-container.hi {
  border: 0.5px solid rgba(91, 195, 255, 1);
}

.custom-selector-checkbox.MuiSvgIcon-root {
    height: 13px! important;
    width: 13px! important;
}

.custom-selector-checkbox.MuiSvgIcon-root.en {
    color: rgba(255, 35, 94, 1);
}

.custom-selector-checkbox.MuiSvgIcon-root.hi {
    color: rgba(91, 195, 255, 1);
}