.detailed-result-item-wrapper {
  border-radius: 17px;
  border: 1px solid rgba(55, 55, 55, 0.03);
}

.detailed-result-item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  width: 100%;
}

.detailed-result-item-icon {
  border-radius: 5px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-top: 8px;
}

.detailed-result-item-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 4px;
  width: 60%;
}

.detailed-result-item-heading {
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #373737;
}

.detailed-result-item-subheading {
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #373737;
}

.detailed-result-item-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
}

.detailed-result-item-btn {
  width: 24px;
  height: 24px;
}

.detailed-result-item-attention {
  border-radius: 0px 0px 4px 4px;
  background: #d42b53;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 1px 8px;
  width: fit-content;
  float: right;
}

.detailed-result-item-opendropdown {
  border-top: 1px solid rgba(55, 55, 55, 0.23);
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.detailed-result-item-opendropdown-word {
  border-radius: 2px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 2px 6px;
  color: #7a7a7a;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.38px;
}

@media (min-width: 305px) and (max-width: 359px) {
  .detailed-result-item-action {
    margin-right: 4%;
  }
  .detailed-result-item {
    gap: 2px;
  }
}
